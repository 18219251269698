.loader {
  // need to have padding to avoid loader dynamic height change issue
  // this issue occurs because mui loader icon uses rotate animation
  // animation: MuiCircularProgress-keyframes-circular-rotate 1.4s linear infinite;
  padding: 10px;
  text-align: center;
}

.fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 3;
}
