@import '~/ui/assets/styles/colors.scss';

.expandIcon {
  color: $color-green-1 !important;
  padding: 5px !important;
}

.menuContainer {
  position: relative;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.menuWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
}

.menu {
  position: relative;
  width: 200px;
  margin: 0;
  padding: 10px;
  list-style-type: none;
  background-color: $color-white;

  &::before {
    content: '';
    position: absolute;
    top: -7px;
    left: 10px;
    width: 0;
    height: 0;
    border-bottom: 1px solid $color-grey-1;
    border-right: 1px solid $color-grey-1;
    background-color: white;
    width: 12px;
    height: 12px;
    transform: rotate(225deg);
  }
}

.link {
  display: flex;
  align-items: center;
  padding: 5px;
  color: $color-black-1;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $color-blue-1;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px !important;
  height: 28px !important;
  margin-right: 7px;
  padding: 5px;
  color: $color-blue-1;
  background-color: $color-grey-3;
  border-radius: 50%;
}
