@import 'fonts.scss';
@import 'colors.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  color: $color-black-1;
}

.react-datepicker-popper {
  z-index: 2;
}

.react-tel-input {
  .special-label {
    left: 10px;
    color: rgba(0, 0, 0, 0.54);
  }
}
